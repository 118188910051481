

  .display-content{
    display: contents;
  }
  
  .logo {
    width: 100%;
    max-width: 120px;
    /* margin-bottom: 20px; */
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #002366; /* Navy Blue */
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .register-button {
    width: 80%;
    padding: 10px 0;
    margin-bottom: 10px;
    background-color: #FF0000; /* Red */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-button {
    width: 80%;
    padding: 10px 0;
    margin-bottom: 20px;
    background-color: #fff;
    color: #FF0000; /* Red */
    border: 1px solid #FF0000;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  .googlebtn{
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
  }
  
  .or-text {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .google-button {
    width: 80%;
    padding: 10px 0;
    background-color: #fff;
    color: #000;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  