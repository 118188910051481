/* Higher priority styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0 20px;
}
.sticky-button-container {
  position: fixed; 
  top: 0;
  background: white;
  /* width: 100%; */
  z-index: 1000; /* Ensures the fixed container is above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better visibility */
}

.mt-50 {
  margin-top: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}

.headbackbtn {
  transform: translate(-79px, -13px);
}



.back-button {
  align-self: flex-start;
  width: 25px;
  background: none;
  border: none;
  cursor: pointer;
}

.logo {
  width: 100%;
  max-width: 120px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #002366; /* Navy Blue */
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
  padding-left: 7px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.fontstyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}
.pb-20{
  padding-bottom: 20px;
}
.red{
  color: red !important;
}
.membershipplan{
  color: white !important;
    background: #FF9D0A;
    padding: 3px 5px;
}

.smalltextlogoone {
  width: 70px;
  height: 20px;
}

.text {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.register-button {
  width: 80%;
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: #ff0000; /* Red */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.icon-inside-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* .lock-icon {
  left: 20px; 
  width: 20px; 
  height: 20px; 
} */

.visibility-icon {
  right: 20px; /* Adjust this value if needed */
  width: 20px; 
  height: 20px; 
  cursor: pointer;
}

.input-field {
  width: 100%;
  padding: 10px 40px 10px 40px; /* Adjust padding for both icons */
  outline: 1px solid rgb(226, 226, 226) !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  background: none;
  border-radius: 5px;
  box-sizing: border-box;
}


.red-link {
  color: #000000;
  text-decoration: none;
}
.underline{
  text-decoration: underline;
}

.bolder {
  font-weight: 700 !important;
  font-size: 14 !important;
}

.hrline {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.or-text {
  position: relative;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  padding: 0 10px;
  z-index: 1;
  white-space: nowrap;
  margin-bottom: 13px;
}

hr {
  position: absolute;
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.login-button-login {
  width: 80%;
  padding: 10px 0;
  margin-top: 15px;
  background-color: #fff;
  color: #ff0000; /* Red */
  border: 1px solid #ff0000;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Lower priority styles */
.verify-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.verify-account-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.back-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.verify-title {
  font-size: 18px;
  margin-top: 40px;
  font-weight: 600;
  text-align: left;
  width: 100%; 
  padding-left: 10px; 
}
.verify-titlee {
  font-size: 18px;
  margin-top: 40px;
  font-weight: 600;
  text-align: center;
  width: 100%; 
  padding-left: 10px; 
}
.passmustcontainchar{
  margin-top: -13px;
  margin-left: -109px;
}

.verify-instructions {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  color: rgb(0 0 0);
}
.platformamount{
  font-size: 20px;
  font-weight: 800;
  color: red;
  outline: 1px solid black;
  border-radius: 5px;
  padding: 10px 20px;
}

.email-highlight {
  font-weight: 600;
  font-size: 12px;
  color: rgba(30, 41, 59, 1);
}

.input-container {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}

.icon-size {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  margin-left: 10px;

}

.input-field {
  width: 100%;
  padding: 10px 10px 10px 40px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.resend-text{
  font-size: 12px;
  font-weight: 400;
  color: rgba(71, 85, 105, 1);
}
.colournone{
  color: rgba(148, 163, 184, 1);
}
.font-500{
  font-weight: 500;
}

.verify-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}
.tclink{
  color: #565656;
}
.verify-button2 {
  
  bottom: 0;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
    padding-left: 4px;
}

.how-to-earn-container {
  padding: 20px;
  text-align: center;
}

.how-to-earn-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.earn-step {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.earn-icon {
  width: 60px;
  margin-bottom: 10px;
}

.earn-step-title {
  font-size: 16px;
  font-weight: 500;
  margin: -6px ;
}

.earn-step-description {
  font-size: 15px;
    font-weight: 400;
    color: rgba(30, 41, 59, 1);
    line-height: 23px;
    letter-spacing: 1px;
}

.next-button {
  width: 100%;
  padding: 15px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
/* .dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
padding-left: 10px;
padding-right: 10px;
} */



/* Top Buttons */
.top-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}
.f-400{
  font-weight: 400;
}

.top-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  max-width: 132px;
  min-width: 85px;
  height: 36px;
}

.top-button .icon {
  width: 20px; /* Adjust icon size */
  height: 20px;
}

.top-button.user {
  background-color: #ffb74d;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
}

.top-button.refer-earn {
  background-color: #f44336; /* Match the color from the image */
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.top-button.deposit {
  background-color: #4caf50; /* Match the color from the image */
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

/* Add shadow and border-radius for better appearance */
.top-button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.top-button:hover {
  transform: scale(1.05);
}

/* Remaining styles for other components */


.views-info, .balance-info {
  display: flex;
  align-items: center;
}

.views-info img {
  /* margin-right: 10px; */
}

.balance-info {
  font-size: 24px;
  font-weight: bold;
  background-color: #FF9D0A;
  width: 274px;
  height: 76px;
  border-radius: 10px;
}

.withdraw-button {
  background-color: #2196f3;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
}





.video-title {
  font-size: 18px;
  color: #1f2937;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}


.video-player-index  {
  position: relative;
  padding-top: 300px; 
  width: 100%;
  height: 0;
}

.video-player-index  iframe {
  width: 100%;
  height: 100%;
  border: none;
  width: 100%;
  max-width: 480px;  
  height: 290px;
}

.video-player-index  > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-player {
  position: relative;
  padding-top: 300px; 
  width: 100%;
  height: 0;
}

.video-player iframe {
  width: 100%;
  height: 100%;
  border: none;
  width: 100%;
  max-width: 480px;  
  height: 290px;
}

.video-player > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.play-pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  outline: none;
}

.play-icon,
.pause-icon {
  font-size: 24px;
}



.progress {
  height: 100%;
  background: linear-gradient(to right, #64748B 0%, #334155 100%);
}

.video-details {
  text-align: center;
  color: #1f2937;
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
    align-items: center;
}
.flex-row-center{
  display: flex;
    flex-direction: row;
    align-items: center;
}

.mr-10{
  margin-right: 10px;
}
.video-timer {
  text-align: center;
    color: #1f2937;
    margin-top: 13px;
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
}
.mb-17{
  margin-bottom: 17px;
}


.partners-section {
  text-align: center;
}

.partner-image {
  max-width: 100%;
  border-radius: 5px;
}

.payment-icon{
  max-width: 190px;
}
.error-icon{
  max-width: 99px;
}





.balance-title {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.balance-amount {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
}

.withdraw-section {
  margin-top: 20px;
  text-align: center;
}

.withdraw-instruction {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.withdraw-amount-box {
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  background-color: #f8f9fa;
}

.withdraw-slider {
  width: 100%;
  margin: 10px 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
}

.upi-section {
  margin-top: 20px;
}

.upi-title {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

.upi-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 5px;
}

.upi-instruction {
  font-size: 14px;
  color: #777;
  text-align: left;
}

/* .withdraw-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
} */

.safety-note {
  text-align: center;
  font-size: 14px;
  color: green;
  margin-top: 10px;
}

.profile-container {
 width: 100%;
}

.profile-options {
  display: flex;
  flex-direction: column;
}

.profile-option {
  padding: 10px 0; /* Adjust padding as needed */
  border-bottom: 1px solid #ccc; /* Adjust color and thickness as needed */
}

.profile-option:last-child {
  border-bottom: none; /* Remove the border from the last option */
}

.button-div{
  background-color: white;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px auto;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.green{
  color: rgba(0, 170, 99, 1);
}
.profile-email,
.profile-phone {
  font-size: 16px;
  color: #666;
  margin: 5px 0;
}

.profile-options {
  margin: 20px 0;
}

.profile-option {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.option-icon {
  width: 24px;
  height: 24px;
  margin-right: 30px;
}

.option-text {
  font-size: 18px;
  color: #333;
  flex: 1;
  text-align: left;
}

.go-back-button {
  width: calc(100% - 40px);
  max-width: 300px;
  margin: 20px auto;
  padding: 15px 0;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  /* bottom: 20px; */
  left: 50%;
  transform: translateX(-50%);
}


.refer-status {
  margin: 20px 0;
  padding: 10px;
  background-color: #eaf4ff;
  border: 1px solid #007bff;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.refer-count {
  color: red;
  font-weight: bold;
}

.refer-title {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.referral-image {
  width: 100%;
  max-width: 150px;
  margin: -24px auto;
  margin-top: 20px;
  z-index: -1;

}

.refer-steps {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  border: 2px solid rgba(60, 154, 251, 1);
  border-radius: 10px;
  /* padding-top: 15px;
  padding-bottom: 15px; */
}





.step-number {
  font-size: 24px;
  font-weight: bold;
  color: rgba(1, 20, 123, 1); /* Blue text */
}

.divider {
  width: 2px;
  height: 84px;
  background-color: rgba(60, 154, 251, 0.3);
  /* margin: 0 20px; */
}


.step {
  text-align: center;
  font-size: 14px;
}



.referral-code-container {
  margin: 20px 0;
}

.referral-code-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(71, 85, 105, 1);
  display: block;
}

.referral-code-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.mr-17{
  margin-right: 17px;
}
.mr-11{
  margin-right: 11px;
}
.referral-code {
  font-size: 12px;
  font-weight: bold;
}

.copy-button {
  padding: 5px 10px;
  color: rgba(71, 85, 105, 1);
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
}
.ml-5{
  margin-left: 5px;
}

.social-invite-container {
  margin: 20px 0;
}

.social-invite-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: space-around;
}

.social-icon {
  width: 50px;
  height: 50px;
}
.social-icon-share{
  /* width: 45px;
  height: 45px; */
  height: 62px;
}

.referandshare{
  color: red;
  outline: 1px solid rgba(60, 154, 251, 1);
  padding: 10px;
  border-radius: 10px;
  font-size: 13px;
}
.flex-col-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fade-colour{
  color: rgba(100, 116, 139, 1);
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 270px;
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-content {
  margin-top: 10px;
}

.error-icon {
  font-size: 50px;
  color: red;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.retry-button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.white-refer{
  color: white !important;
  font-size: 14px !important;
  font-weight: 700 !important;

}









.header {
  text-align: center;
}

.header h1 {
  font-size: 24px;
  color: #d48b91;
  margin-bottom: 5px;
}

.header p {
  font-size: 14px;
  color: #666;
}

.roles-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.role-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.role-card.selected {
  border-color: #f0c0ca;
  background-color: #fce8ec;
}

.role-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.role-card p {
  font-size: 14px;
  color: #333;
  text-align: center;
}

.next-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #d48b91;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.next-button:disabled {
  background-color: #ccc;
}






/* <-------> */


.top-button {
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  outline: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.top-button .icon {
  margin-right: 5px;
}

.account-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  padding-top: 10px;
  background-color: #fff;
  /* border */
}
.bold{
  font-weight: 800;
}
.ml-7{
  margin-left: 7px;
}
.mr-2{
margin-right: 2px;
}
.view-bg{
  background-color: #3C9AFB;
  border-radius: 10px;
  color: white;
  width: 104px;
  height: 76px;
  margin-right: 10px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.mt-10{
  margin-top: 10px;
}
.flex-row{
  display: flex;
  flex-direction: row;
}

.video-section {
  background: #f9fafb; 
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  outline: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}
.congowithdrawal{
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 10px;
    outline: 1px solid rgba(0, 0, 0, 0.1);
}

.displayfix{
  justify-content: left !important;
}


.gradient-background {
  width: 55%;
  height: 3px; 
  background: linear-gradient(to right, #ffffff, #ff0000, #ffffff);
}
.gradient-background-deposit{
  width: 100%;
  height: 3px; 
  background: linear-gradient(to right, #ffffff, #ff0000, #ffffff);
  margin-bottom: 30px;
}




.play-pause-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.video-details {
  margin-top: 10px;
  text-align: center;
  padding-right: 20px;
}

.view-count {
  margin-top: 10px;
  font-weight: bold;
}
.popup-title {
  margin-top: 10px;
  font-size: 1.25rem; /* Adjust size as needed */
  font-weight: bold;
}

.popup-body {
  margin-top: 20px;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.popup-footer .action-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.popup-footer .action-button:hover {
  background: #0056b3;
}

.popup-footer .cancel-button {
  background: #6c757d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;
  transition: background 0.3s ease;
}

.popup-footer .cancel-button:hover {
  background: #5a6268;
}


.progress-bar .progress {
  height: 100%;
  background-color: #76c7c0;
  width: 0;
  transition: width 0.2s ease;
}


.progress-bar-container {
  position: relative;
  width: 100%;
  height: 10px;
  /* background-color: #e0e0e0; Background color for the progress bar container */
  border-radius: 5px;
  margin: 60px 0 0 0;
}

/* .progress-bar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
} */

.progress-image {
  position: absolute;
  top: -15px; /* Adjust this value as needed to position the image correctly */
  left: calc(100% - 43px); /* Position the image at the end of the progress bar */
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  padding: 20px;
  position: relative;
}

.popup-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-content {
  margin-top: 20px;
}

.popup-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.popup-title {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.popup-message {
  font-size: 18px;
  color: #4CAF50;
  margin: 0;
}
.popup-message-pop {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.retry-button {
  background: #ff5722;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.progress-bar {
  height: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 4px !important;
  margin-right: 40px;
}


.progress {
  height: 100%;
  background-color: #f44336; /* Progress bar color */
  transition: width 0.2s ease-in-out; /* Smooth transition for the progress bar */
}


.account-info .views-info,
.account-info .balance-info {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.account-info .views-info img.views-icon,
.account-info .balance-info img {
  width: 30px;
  height: 30px;
  /* margin-right: 10px; */
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.f12-white{
font-size: 12px;
color: rgb(255, 255, 255);
}
.account-info .balance-amount {
  font-size: 31px;
  color: #333;
  margin-right: 10px;
  width: 103px;
  height: 54px;
  text-align: left;
  margin-left: 5px;
}

.account-info .withdraw-button {
  background: #2196f3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  width: 107px;
  height: 41px;
  cursor: pointer;
  font-weight: 700;
}

.play-button {
    background: none;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 1;
    transform: translateY(-136px);
}

.play-button:hover {
  opacity: 1;
  background: none;
}

.video-section .progress-bar {
  margin-top: 10px;
}

.video-section .progress-bar .progress {
  height: 100%;
  background: #f44336;
  transition: width 0.2s;
}



@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

.flexx{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
}




/* Ensure the partners section has appropriate padding and alignment */
.partners-section {
  padding: 40px 20px;
  background-color: #f7f7f7; /* Adjust background color as needed */
  text-align: center;
}

.partners-slider {
  max-width: 80%;
  margin: 0 auto;
}

.partner-image {
  width: 100%;
  height: auto;
  max-height: 200px; 
  object-fit: cover;
}

.slick-dots li button:before {
  color: #ffffff; 
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ffcc00; 
}

.slick-prev:before,
.slick-next:before {
  color: #ffffff;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.popup-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.retry-button {
  background-color: #dc3545;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #c82333; 
}

.slick-list{
  width: 100%;
  max-width: 350px;
}


.membership-plans {
  margin: 0;
  padding: 10px;
  font-size: 14px;
}

.plans-title {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
}



.plan h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  margin-top: 10px;
}

.plan p {
  margin: 2px 0;
  font-size: 12px;
}



.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.confirm-button,
.cancel-button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
}
.flexcenterf{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.play-icon{
  width: 50px;
}
.membership-container {
  width: 100%;
    max-width: 370px;
    padding-left: 10px;
    padding-right: 10px;
    height: 242px;
    font-size: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    outline: 1px solid rgba(0, 0, 0, 0.1);
}



.plan {
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;

  /* padding: 10px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
}

.free-plan{
  text-align: justify;
}
.ml-8{
  margin-left: 8px;
}
.plan p, .plan-detail, .deposit-item {
  margin: 2px 0;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.membershipboxdeposit{
  margin: 2px 0;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  color: black;
  font-weight: 500;
  margin-bottom: 16px !important;
  outline: 1px solid red;
  padding: 3px 7px;
}
.bullet{
  color: #FF9D0A;
 
}
.green{
  color: #00AA63;
}
.font60014{
  font-size: 14px;
  font-weight: 600;
}
.deposit-button {
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background-color: #00AA63;
  outline: none;
  text-decoration: none;
  border-radius: 4px;
  margin-left: 20px;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.confirm-button,
.cancel-button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
}


.google-login-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.google-login-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.google-login-btn:hover {
  background-color: #357ae8;
}

.outlinediv{
  box-shadow: 0px 0px 4px 0px rgba(21, 39, 69, 0.2);
  padding: 5px;
  border-radius: 10px;
}
.mb-10{
  margin-bottom: 10px;
}

.points-display-wrapper {
  display: inline-block;
  position: relative;
}

.text-skeleton-loader {
  display: inline-block;
  width: 100px; /* Adjust width as needed */
  height: 1em; /* Adjust height as needed */
  background: linear-gradient(90deg, #ff9d0a00 25%, #e0e0e060 50%, #ff9d0a00 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


/* Edit Profile */
.edit-profile-container {
  margin-top: 20px;
  text-align: left;
}

.edit-profile-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.edit-profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}





/* payment gateway page css */
.payment-success-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
}

.success-icon {
  margin-bottom: 20px;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #e0f7ea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  font-size: 48px;
  color: #4caf50;
}

h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666666;
  margin: 5px 0;
}

.payment-details {
  margin-top: 20px;
}

.amount {
  font-weight: bold;
  color: #4caf50;
}

.payment-method {
  font-weight: bold;
  color: #2196f3;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #45a049;
}

.loading {
  font-size: 18px;
  color: #666666;
}

.error-message {
  color: red;
  font-size: 16px;
}




/* failure page css */
.payment-failure-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
}

.failure-icon {
  margin-bottom: 20px;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fdecea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crossmark {
  font-size: 48px;
  color: #f44336;
}

h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666666;
  margin: 5px 0;
}

.payment-details {
  margin-top: 20px;
}

.amount {
  font-weight: bold;
  color: #f44336;
}

.payment-method {
  font-weight: bold;
  color: #2196f3;
}

.retry-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #e53935;
}

.loading {
  font-size: 18px;
  color: #666666;
}

.error-message {
  color: red;
  font-size: 16px;
}


.input-invalid {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
}
.mr-20{
  margin-right: 20px;
}
.pd20{
  padding: 20px;
}
.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}









.spacious {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px; 
}

.plans-title-deposit {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.plans-list {
  display: flex;
  flex-direction: column;
  gap: 20px; 
}

.spacious-plan {
  padding: 20px;
  border: 1px solid #ccc; 
  border-radius: 8px;
  background-color: #f9f9f9;
}

.deposit-item.spacious-deposit-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.spacious-popup {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: white; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.membershipback{
  display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}




.your-mistake-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.your-mistake-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #343a40;
}

.your-mistake-description {
  font-size: 1.125rem;
  margin-bottom: 30px;
  color: #6c757d;
}

.mistake-list {
  margin-bottom: 30px;
}

.mistake-item {
  margin-bottom: 20px;
}

.mistake-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #495057;
}

.mistake-content {
  font-size: 1rem;
  color: #343a40;
}

.reminder {
  font-size: 1.125rem;
  margin: 20px 0;
  color: #17a2b8;
}

.conclusion-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: #495057;
}

.conclusion-content {
  font-size: 1rem;
  color: #343a40;
}


.keep-watching-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 30px;
  background-color: red; 
  color: white;
  font-size: 1.125rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.keep-watching-button:hover {
  background-color: #00AA63; 
}


.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px; /* Adjust the size as needed */
  margin-bottom: 15px;
}

.qr-section {
  margin: 20px 0;
}

.qr-image {
  width: 200px;
  height: 200px;
  border-radius: 8px;
}

.upi-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.upi-label {
  font-weight: bold;
  color: #555;
}

.upi-id {
  font-size: 16px;
  color: #333;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.share-button,
.download-button {
  padding: 8px 16px;
  background-color: #00AA6a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.share-button:hover,
.download-button:hover {
  background-color: #035734;
}

.payment-details {
  margin-top: 20px;
  text-align: center;
}

.payment-details p {
  font-size: 16px;
  color: #333;
}
