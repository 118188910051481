/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.demoWebImg{
  width: 100%;
  max-width: 406px;
}
.App {
  text-align: center;
}



.logo {
  font-size: 28px;
  font-weight: bold;
  color: #ff0000;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #ff0000;
}

.download-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #cc0000;
}
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 1rem;
  z-index: 1000;
}
.dropdown-menu a, .dropdown-menu button {
  margin: 0.5rem 0;
}

/* Media Queries */
@media (max-width: 768px) {
  /* .nav {
    display: none;
  } */

  .menu-icon {
    display: block;
  }

  .dropdown-menu {
    display: none; /* Initially hide dropdown menu */
  }

  .dropdown-menu.show {
    display: flex;
  }
}

/* Hero Section */
.hero {
  padding: 40px;
  background: linear-gradient(to bottom, #63738A, #FFFFFF);
  color: #333333;
}

.hero h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}
.smalltextlogo {
  width: 160px;
  height: 44px;
}

/* In your CSS file or within a <style> tag */
.white-link {
  color: white;
  text-decoration: none;  /* Optional: remove underline */
}

.white-link:hover {
  color: lightgray;  /* Optional: change color on hover */
}

.hero p {
  font-size: 20px;
  margin-bottom: 30px;
  color: white;
}

.video-container {
  position: relative;
  display: flex;
  justify-content: center;
}


.video-img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.get-started-btn {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* How to Earn Section */
.how-to-earn {
  background-color: #ffffff;
  padding: 40px;
}

.how-to-earn h2 {
  font-size: 36px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 30px;
}

.earn-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.option {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 250px;
}

.option img {
  width: 80px;
  margin-bottom: 10px;
}


.mrmaojfontcolour{
  font-size: 24px;
  color: #ffe6e6 !important;
  margin-bottom: 10px;
}

.option p {
  font-size: 16px;
  color: #666666;
}

.maxwidth567{
  max-width: 567px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* About Us Section */
.about-us {
  background-color: #F1F5F9;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-bottom: 64px;
}

.about-us h2 {
  font-size: 36px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 30px;
}

.about-content {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.about-content img {
  width: 100%;
  max-width: 372px;
  border-radius: 10px;
  /* padding-right: 30px; */
}

.contact-info {
  text-align: left;
}

.contact-info h3 {
  font-size: 24px;
  color: #fbdede;
}

.contact-info p {
  font-size: 16px;
  color: #666666;
  margin: 5px 0;
}
.details-container{
  max-width: 451px;
}
.contact-info img {
  width: 100px;
  height: 100px;
}
.mrmanojsection{
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2f3a4a;
    /* color: white !important; */
    border-radius: 10px;
    border-radius: 10px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 10px;
    padding-right: 10px;
}
.Mrmanoj{
  width: 372px;

}
/* Every Minute Counts Section */
.every-minute-counts {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  background-color: #CBD5E1;
  padding: 40px;
}
@media (max-width: 911px ) {
  .every-minute-counts {
    display: flex;
    flex-direction: column;
  }
}

.every-minute-counts h2 {
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.every-minute-counts p {
  font-size: 20px;
  color: #666666;
  margin-bottom: 20px;
}
.refernearn{
  height: 100%;
  max-height: 104px;
  width: 100%;
}
.getStartedNow{
  width: 100%;
  max-width: 320px;
}

.getStartedNowpopup{
  width: 100%;
  max-width: 220px;
  margin-top: 20px;
}

.google-play-btn {
  width: 100%;
  max-width: 235px;
  border: none;
  cursor: pointer;
}

/* Contact Us Section */
.contact-us {
  background-color: #f0f0f0;
  padding: 40px;
}

.contact-us h2 {
  font-size: 36px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form button {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 2rem; */
}

.contact-details p {
  font-size: 16px;
  color: #ffffff;
  margin: 5px 0;
}
.maxwidth567 {
  width: 100%;
  max-width: 567px;
  margin-top: 2rem;
}
/* Footer */
.footer {
  background-color: #CBD5E1;
  color: #565656;
  padding: 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logofootersection, .footer-links, .footer-content {
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links h5 {
  margin-bottom: 10px;
}

.footer-links span {
  margin-bottom: 5px;
}

.footer-content a {
  color: white;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .logofootersection, .footer-links, .footer-content {
    margin-bottom: 0;
  }

  .footer-links {
    flex-direction: row;
    justify-content: space-around;
  }

  .footer-links span {
    margin-right: 20px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav a {
    margin: 0 10px;
    font-size: 16px;
  }

  .download-btn {
    padding: 8px 16px;
    font-size: 16px;
  }

  .hero h1 {
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
  }

  .hero p {
    font-size: 18px;
    color: white;
  }

  .how-to-earn h2,
  .about-us h2,
  .every-minute-counts h2,
  .contact-us h2 {
    font-size: 28px;
  }

  .earn-options {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  .about-content {
    flex-direction: column;
  }
}
/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
}

.nav a, .nav button {
  margin-right: 1rem;
  text-decoration: none;
  color: #333;
}

@media (max-width: 480px) {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

  .nav {
    flex-direction: row;
    align-items: flex-start;
    font-weight: 700;
  }

  .nav a {
    margin: 5px 5px;
  }

  .download-btn {
    margin-top: 10px;
  }

  .hero h1 {
    font-size: 24px;
    color: white !important;
  }

  .hero p {
    font-size: 16px;
    color: white;
  }

 

  .play-btn {
    padding: 8px 16px;
  }

  .get-started-btn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .how-to-earn h2,
  .about-us h2,
  .every-minute-counts h2,
  .contact-us h2 {
    font-size: 24px;
  }

  /* .earn-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  } */

  .option {
    width: 100%;
  }

  .about-content {
    flex-direction: column;
  }

  .google-play-btn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .contact-form input,
  .contact-form textarea {
    max-width: 100%;
  }

  .map {
    height: 200px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links a {
    margin: 5px 0;
  }
}

.video-player-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 8px;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.video-player.hidden {
  opacity: 0;
}

.video-player.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
